::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: white;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #107e7b;
}

// custom-antd.less
@import "~antd/dist/antd.less"; // Import Ant Design Less styles
@import "_custom-variables.less";

// Override primary color
// @major-color: #05aca8;
// @primary-color: @major-color;

// @minor-color: #ebf1ef;

// Common class
// .d-flex
// End Common class

.ant-page-header {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

a.ant-typography {
  text-decoration: underline !important;
}

.ant-form-item-explain-error {
  white-space: break-spaces;
}

// Layout
header.ant-layout-header {
  background-color: @table-header-bg !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

.ant-input-password {
  height: 40px;
  padding-top: 0 !important;
  .ant-input {
    height: 38px !important;
  }
}

.ant-input-group-wrapper {
  input.ant-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .ant-input-affix-wrapper {
    height: 40px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }
}

.ant-input-number,
.ant-select-selector,
.ant-input {
  border-color: @major-color !important;
  color: @major-color !important;

  &.ant-input-status-error {
    border-color: @error-color !important;
  }
}
.ant-input-affix-wrapper {
  // height: 40px !important;
  // padding-top: 0 !important;
  // padding-bottom: 0 !important;
  border-color: @major-color;
  // border-top-right-radius: 6px !important;
  // border-bottom-right-radius: 6px !important;
}

.ant-layout {
  background: @minor-color !important;
  min-height: 100vh;
}

aside {
  background-color: @major-color !important;
}

span.ant-input-group-addon {
  background-color: @major-color;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid @major-color !important;
}

// Table
.ant-table {
  .ant-table-title {
    border: none !important;
    padding: 15px 0 15px 0 !important;
  }
}
//// pagination
ul.ant-pagination {
  .ant-select-selector,
  li,
  li > button {
    color: @primary-color;
    border-radius: 0 !important;
    border-color: @primary-color !important;
  }

  li {
    border-right: 0px;
    margin-right: 0 !important;
    &.ant-pagination-item-active {
      background-color: @primary-color;
      a {
        color: white !important;
      }
    }

    &.ant-pagination-item-1 {
      border-left: 0;
    }

    a {
      color: @primary-color;
    }
  }

  svg {
    fill: @primary-color;
  }
}

li.ant-pagination-options {
  div.ant-select-selector {
    height: 32px !important;
  }
  .ant-select-selection-item {
    line-height: 32px !important;
  }
}

// Button
// .ant-radio-group .ant-radio-button-wrapper
.ant-btn.ant-btn-sm {
  padding: 0 15px !important;
  height: 34px !important;
  line-height: 34px !important;
}
.ant-upload-list-item-card-actions > .ant-btn.ant-btn-sm {
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
}

//HR
hr.custom-hr {
  background: @table-border-color !important;
  border: 1px;
}

// Card
.ant-card {
  .ant-card-head {
    border-bottom: none !important;
    position: relative;

    .ant-card-head-title::after {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 24px;
      width: calc(100% - 46px);
      border-bottom: 2px solid @primary-color;
    }
  }
}

// step bar
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #ccc;
}

// left side bar
// li.ant-menu-submenu {
//   &.ant-menu-submenu-open {
//     // background: #9eccce !important;
//   }

//   div.ant-menu-submenu-title {
//     color: white !important;
//     padding: 0px 26px !important;

//     .ant-menu-submenu-arrow {
//       color: white !important;
//     }
//   }

//   ul.ant-menu.ant-menu-sub {
//     li {
//       font-size: 0.8rem;
//       &.ant-menu-item-active.ant-menu-item-selected {
//         a.active {
//           box-shadow: none !important;
//           &::after {
//             content: "";
//             width: 253px;
//             height: 1px;
//             background-color: white;
//             position: relative;
//             left: 12px;
//           }
//         }
//       }
//     }
//   }
// }

li.ant-menu-sub-custom {
  .ant-menu-submenu-arrow {
    color: white !important;
  }

  .ant-menu-submenu-title:hover {
    color: white !important;
  }

  .facilities-group-parent {
    &>div.ant-menu-item-group-title {
      background-color: @major-active-dark-color !important;
    }

    ul.ant-menu-item-group-list {
      max-height: 500px;
      overflow-x: hidden;
      overflow-y: auto;
      background: @major-active-color !important;
  
      li {
        .ant-menu-item-group-title {
          padding: 0 !important;
          line-height: 0 !important;
  
          a {
            font-size: 0.8rem;
            line-height: 28px;
            padding: 4px 10px 4px 44px;
            &.active {
              background-color: #9ecbce !important;
            }
  
            display: inline-block !important;
            width: 300px !important;
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
        }
      }
    }
  }
}

// spin, loading
.spin-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

// common-card
.common-card {
  min-height: calc(100vh - 80px);
}

.left-sidebar-app-version-container {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  border-top: 1px solid @table-border-color;
  color: white;
  display: flex;
  justify-content: center;
  background-color: @primary-color;

  .app-release-version {
    color: white !important;
  }
}

.app-release-version {
  color: @table-header-bg;
}

.custom-left-sidebar-menu {
  margin-bottom: 40px !important;
}

.custom-note {
  color: @primary-color;
}

// yes-no-selector-checkbox
.yes-no-selector-checkbox {
  .ant-form-item-control-input {
    min-height: 6px !important;
  }
}

// user infor dropdown
li.user-info-dropdown-logout-item {
  border-top: 1px solid @table-border-color;
}
li.user-info-dropdown-update-user-info-item {
  text-align: center;
}

// header fixed
.page-header-custom {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  .header-username {
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden; 
    max-width: 200px; 
    white-space: nowrap;
  }

  .invitation-message {
    color: @primary-color;
    width: 24px;
    height: 24px;
    border: 1px solid;
    border-radius: 100%;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
}

// custom content
.page-content-custom {
  margin-top: 40px;
}
